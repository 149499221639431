require('./bootstrap');

import 'preline/preline';



import Alpine from 'alpinejs';

window.Alpine = Alpine;
Alpine.start();





